<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Radio Button</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>A radio button is best indicated in a small group of options.</p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h2>General Guidelines</h2>
            <ul class="bullet-red">
              <li>
                Radio button groups should only have one selection allowable per
                group. If you require multiple selections use the multiple
                selections pattern instead.
              </li>
              <li>
                The advantage of a radio button group is the amount of
                additional context that can be included in the label.
              </li>
              <li>
                Radio buttons should appear in a stacked fashion, they can be
                used side-by-side in the following circumstances:
              </li>
              <ul class="sublist">
                <li>
                  If there are only two options (typically yes/no).
                </li>
                <li>
                  If the radio buttons are being used as a filter along the top
                  of data element.
                </li>
              </ul>
            </ul>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h3>Checkbox</h3>
            <CodeView :component="primary.main">
              <RadioButtonComponent type="primary" text="Button" />
            </CodeView>
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
// import StickyTOC from "../StickyTOC";
import RadioButtonComponent from "../RadioButtonComponent";
import CodeView from "../CodeView";
import TempLeftNav from "../TempLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "RadioButton",
  data: function() {
    return {
      primary: {
        main: {
          codeHTML:
            "<button id='button-js--primary' class='button button--primary'>Button</button>",
          codeCSS: `           .button { 
                      padding: 12px 25px; 
                      width: 140px; 
                      color: white; 
                      background-color: #1a5192; 
                      text-align: center; 
                      border-radius: 20px; 
                      border: none; 
                      font-size: 1rem; 
                      cursor: pointer;
                    }`,
          codeJS:
            "document.getElementById('button-js--primary').addEventListener('click', ()=> {console.log('Hello, world!')});",
          preview: "This is the preview",
          name: "Radio set (default)",
        },
      },
    };
  },
  components: {
    CodeView,
    RadioButtonComponent,
    TempLeftNav,
    SubFooter,
    // StickyTOC,
  },
};
</script>