<template>
  <div class="v-input--radio-group__input" role="radiogroup">
    <div
      class="md-radio md-theme-demo-light"
      :class="{ 'md-checked': activeIndex === 1 }"
      @click="handleRadioClick(1)"
    >
      <div class="md-radio-container">
        <div class="md-ripple">
          <input
            type="radio"
            id="md-radio-1"
            value="first"
            :checked="activeIndex === 1"
          />
        </div>
      </div>
      <label for="md-radio-1" class="md-radio-label">First</label>
    </div>
    <div
      class="md-radio md-theme-demo-light"
      :class="{ 'md-checked': activeIndex === 2 }"
      @click="handleRadioClick(2)"
    >
      <div class="md-radio-container">
        <div class="md-ripple">
          <input
            type="radio"
            id="md-radio-2"
            value="second"
            :checked="activeIndex === 2"
          />
        </div>
      </div>
      <label for="md-radio-2" class="md-radio-label">Second</label>
    </div>
    <div
      class="md-radio md-theme-demo-light"
      :class="{ 'md-checked': activeIndex === 3 }"
      @click="handleRadioClick(3)"
    >
      <div class="md-radio-container">
        <div class="md-ripple">
          <input
            type="radio"
            id="md-radio-3"
            value="third"
            :checked="activeIndex === 3"
          />
        </div>
      </div>
      <label for="md-radio-3" class="md-radio-label">Third</label>
    </div>
  </div>
</template>

<script>
// @TODO - Make the radio group usable
// consider the attribute checked to know which radio is checked
export default {
  name: "RadioButtonComponent",
  data: function() {
    return {
      activeIndex: 1,
    };
  },
  methods: {
    handleRadioClick: function(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style>
.v-input--radio-group__input {
  display: flex;
  flex-direction: column;
  border: none;
  cursor: default;
  width: 100%;
}

.md-radio:not(.md-disabled),
.md-radio:not(.md-disabled) .md-radio-label {
  cursor: pointer;
}
.md-radio {
  width: auto;
  margin: 16px 16px 16px 0;
  display: inline-flex;
  position: relative;
}
.md-radio.md-theme-demo-light.md-checked .md-radio-container {
  border-color: #ef1217;
}
.md-radio.md-theme-demo-light .md-radio-container {
  border-color: rgba(0, 0, 0, 0.54);
}
.md-radio .md-radio-container {
  width: 20px;
  min-width: 20px;
  height: 20px;
  position: relative;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-radio .md-radio-container::before {
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  z-index: 7;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.md-radio .md-radio-container::after,
.md-radio .md-radio-container::before {
  position: absolute;
  transition: 0.4s cubic-bezier(0.55, 0, 0.55, 0.2);
  content: " ";
}
.md-radio.md-theme-demo-light.md-checked .md-radio-container::after {
  background-color: #ef1217;
}
.md-radio.md-checked .md-radio-container::after {
  opacity: 1;
  transform: scale3D(1, 1, 1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-radio .md-radio-container::after {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: 50%;
  opacity: 0;
  transform: scale3D(0.38, 0.38, 1);
  content: " ";
}
.md-radio .md-radio-container::after,
.md-radio .md-radio-container::before {
  position: absolute;
  transition: 0.4s cubic-bezier(0.55, 0, 0.55, 0.2);
  content: " ";
}
.md-content.md-theme-demo-light {
  color: rgba(0, 0, 0, 0.87);
}
.md-radio .md-radio-label {
  height: 20px;
  padding-left: 16px;
  position: relative;
  line-height: 20px;
}
.md-radio .md-radio-container .md-ripple {
  width: 48px !important;
  height: 48px !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.md-ripple {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 6;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
}
.md-radio .md-radio-container input {
  position: absolute;
  left: -999em;
}
</style>
